import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { parseUrlComponents } from '../../../helpers/api';
import { RouterContext } from '../../../stores/RouterContext';

const UniLink = (props) => {
  const { title, href, target, className, children, tracking, ...attribs } = props;
  const urlComponents = parseUrlComponents(href);
  const hostname = window?.location.hostname;
  const routerContext = useContext(RouterContext);

  /**
   * tracking param can be set to false to disable tracking
   * or { value: 'portal', disable: true }
   * @param {string} url
   * @returns string
   */
  const addTrackingParams = (url) => {
    if (false === tracking || tracking?.disable) {
      return url;
    }
    if (undefined !== tracking?.value) {
      if (Array.isArray(tracking?.value)) {
        tracking.value.map((value) => urlComponents.searchParams.append('sanoa_v', value));
      } else {
        urlComponents.searchParams.append('sanoa_v', tracking?.value);
      }
    }

    routerContext.partnerSlug && urlComponents.searchParams.append('sanoa_p', routerContext.partnerSlug);
    routerContext.locationSlug && urlComponents.searchParams.append('sanoa_l', routerContext.locationSlug);
    routerContext.postType &&
      urlComponents.searchParams.append('sanoa_pt', routerContext.postType.replace('sanoa_', ''));
    routerContext.slug && urlComponents.searchParams.append('sanoa_s', routerContext.slug);

    return urlComponents.toString();
  };

  if (hostname === urlComponents.hostname && !target) {
    return (
      <Link
        className={className ? className : undefined}
        to={{ pathname: urlComponents.pathname, search: urlComponents.search, hash: urlComponents.hash }}
        title={title}
        {...attribs}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <a
        className={className ? className : undefined}
        href={addTrackingParams(href)}
        target={target ? target : undefined}
        title={title}
        {...attribs}
        rel={target ? 'noopener noreferrer' : attribs.rel}
      >
        {children}
      </a>
    );
  }
};

export default UniLink;
